@import '../variables.less';

#treatment-timeline {
  padding: 8px 10px 8px 30px;
}
.treatment-timeline-list {
  margin-top: 20px;
  height: @treatment-timeline-height;
  overflow: auto;

  &.show-filtered {
    height: calc(@treatment-timeline-height - 45px);
  }
}
.treatment-timeline-list .day-range {
  top: 0px;
}
.no-timeline {
  margin: 20px 200px;
  font-weight: bold;
}
.filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.filter-container .path-filter-container {
  margin-right: 20px;
  position: initial;
  z-index: auto;
}

.filter-container .path-filter-container.last-path-filter {
  margin-right: 0;
}
.range-datepicker {
  white-space: nowrap;
}
.filter-container .link-click {
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-bottom: 10px;
  border-left: 1px solid #dadada;
  padding-left: 10px;
  height: 20px;
}
