@import '../variables.less';

@font-face {
  font-family: Nunito;
  src: url('../../fonts/Nunito-Regular.ttf') format('ttf');
}

// * {
//   font-family: Avenir, Nunito, Verdana, Geneva, Tahoma !important;
//   color: #54585d;
// }

.androidDevice {
  letter-spacing: 0.5px;
}

.dashboard-app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.app-content,
.dashboard-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}
.xxxxsmall-font {
  font-size: 8px;
}
.xxxsmall-font {
  font-size: 9px;
}

.xxsmall-font {
  font-size: 10px;
}

.xsmall-font {
  font-size: 11px;
}

.small-font {
  font-size: 12px;
}

.big-font {
  font-size: 16px;
}

.xbig-font {
  font-size: 18px;
}

.xxbig-font {
  font-size: 20px;
}

.xxxbig-font {
  font-size: 22px;
}
.app-container {
  width: calc(100% - @sidebarWidth);
  background-color: #4285f4;
}

.page-container {
  position: relative;
  margin-left: @pageContainerLeftMargin;
  background: white;
  // height: calc(100% - @headerHeight);
}
.app-container-title {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}
.app-container-header {
  // height: @headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}
.app-container-settings path {
  fill: rgba(255, 255, 255, 0.498039);
}

.page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
}
.link-click {
  color: #4285f4;
  cursor: pointer;
}
.menu-list-item-icon {
  width: 14px;
  height: 14px;
}

textarea {
  &:not(.MuiInputBase-input) {
    padding-top: 6px;
  }
}
select,
textarea,
input[type='text'],
input[type='tel'],
input[type='password'],
input[type='date'],
input[type='number'],
input[type='search'] {
  &:not(.MuiInputBase-input) {
    border: 1px solid @borderColor;
    border-radius: 5px;
    background-color: #ffffff;
    width: 200px;
  }
}
select,
input[type='text'],
input[type='tel'],
input[type='password'],
input[type='date'],
input[type='number'],
input[type='search'] {
  &:not(.MuiInputBase-input) {
    height: 36px;
  }
}
select,
textarea,
input[type='text'],
input[type='tel'],
input[type='password'],
input[type='number'] {
  &:not(.MuiInputBase-input) {
    padding-left: 10px;
  }
}

input[type='search'] {
  &:not(.MuiInputBase-input) {
    background: url(../../images/icn_search.svg) no-repeat scroll 10px 12px;
    padding-left: 28px;
  }
}

select::placeholder,
textarea::placeholder,
input[type='text']::placeholder,
input[type='tel']::placeholder,
input[type='password']::placeholder,
input[type='date']::placeholder,
input[type='number']::placeholder,
input[type='search']::placeholder {
  &:not(.MuiInputBase-input) {
    opacity: 0.5;
  }
}

.timepicker.disabled,
select[disabled],
input[type='number'][disabled],
input[type='date'][disabled],
input[type='text'][disabled],
input[type='tel'][disabled],
textarea[disabled],
input[type='password'][disabled] {
  &:not(.MuiInputBase-input) {
    background: @hoverColor;
    color: #a9abae;
  }
}

input[type='checkbox'],
input[type='radio'] {
  &:not(.MuiInputBase-input) {
    cursor: pointer;
  }
}
label {
  &:not(.MuiInputBase-input):not(.MuiFormLabel-root) {
    margin-bottom: initial;
  }
}

.timeline-item-type {
  border-radius: 9px;
  background-color: @grayColor;
  display: inline-block;
  padding: 2px 9px;
  font-weight: 500;
  line-height: 14px;
}
.hide {
  display: none;
}
.loading-data {
  opacity: 0.5;
  pointer-events: none;
}
.page-not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(~'100vh - ' @navHeaderHeight - 60px);
}

[class^='arrow-'] {
  width: 7px;
  height: 7px;
  margin-left: 5px;
  border-top: 1px solid #54585d;
  border-left: 1px solid #54585d;
  margin-bottom: 4px;
  display: inline-block;
  cursor: pointer;
}
[class^='arrow-'].disabled {
  opacity: 0.5;
  cursor: default;
}
.arrow-down {
  transform: rotate(225deg);
}
.arrow-top {
  transform: rotate(45deg);
}
.arrow-right {
  transform: rotate(135deg);
}
.arrow-left {
  transform: rotate(315deg);
}
.sub-menu-nav {
  background: @backgroundColor;
  height: @sub_menu_navigation;
  padding: 0px 30px;
  display: flex;
  align-items: center;
}
.sub-menu-nav a {
  opacity: 0.6;
}
.care-plan-nav a {
  opacity: 1;
}
.sub-menu-nav a,
.sub-menu-nav div {
  color: #54585d;
  margin-right: 32px;
  float: left;
  cursor: pointer;
}
.sub-menu-nav a:hover,
.sub-menu-nav div:hover {
  text-decoration: none;
}
.sub-menu-nav .selected-sub-menu {
  opacity: 1;
  font-weight: bold;
}
.sub-menu-nav .disabled-sub-menu {
  cursor: default;
  color: rgba(0, 12, 98, 0.38);
}
.sub-menu-nav .not-selected {
  opacity: 0.6;
}
.item-card {
  height: 200px;
  width: 136px;
  border-radius: 5px;
  display: grid;
  text-align: center;
  background-color: @backgroundColor;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
  border: 1px solid @backgroundColor;
}
.item-card svg {
  justify-self: center;
}
.required-star {
  color: @redColor;
}
.sub-menu-page {
  height: @plan_content;
}
.logo-input-container {
  position: relative;
}

span.span-logo-icon {
  position: absolute;
  top: 8px;
  left: 7px;
}
.clickable {
  cursor: pointer;
}
.dashboard-page {
  width: 100%;
  min-width: 1130px;
  overflow: auto;
}
.discharge-reason {
  border-radius: 9px;
  background-color: #6b919a;
  display: inline-block;
  color: #ffffff;
  font-weight: 900;
  padding: 5px 8px;
}
.invalid-message {
  color: #d24667;
  font-weight: 300;
}
.system-message-element {
  border-left-width: 3px;
  border-left-style: solid;
  border-radius: 3px;
}
.hide-arrow input::-webkit-outer-spin-button,
.hide-arrow input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.specialitie-tag {
  border: 0.5px solid @grayColor;
  border-radius: 5px;
  margin-right: 6px;
  padding: 0px 6px;
  margin-bottom: 5px;
  white-space: nowrap;
  display: inline-block;
}
.fixed-header {
  position: sticky;
  top: 0;
  background: #ffffff;
}
.multi-options-container {
  display: flex;
  align-items: center;
}
.multi-options-container img {
  border-radius: 30px;
  width: 32px;
  height: 32px;
}
input.invalid-field,
select.invalid-field,
textarea.invalid-field {
  border-color: @redColor !important;
}

input.MuiOutlinedInput-input {
  border: none;
}

.initial-css {
  input[type='text'],
  input[type='number'],
  input[type='tel'],
  select {
    // padding-left: 30px;
    border: initial;
    background-color: initial;
    height: initial;
    box-sizing: initial;
    width: 100%;
    color: initial;
  }
}
