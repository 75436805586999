@import "../variables.less";

.caseload-page {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  height: @dashbord_content_height;

  overflow: auto;
}

.caseload-page-content {
  display: flex;
  overflow: auto;
}
.caseload-page .caseload-page-content {
  padding-top: @caseloadPaddingTop;
}
.treatment-picture {
  width: 80px;
  vertical-align: top !important;
}
.treatment-picture img {
  border-radius: 40px;
  width: 68px !important;
  height: 68px !important;
}
#patient-name-and-picture,
.treatment-picture {
  &.open-alert img {
    width: 58px !important;
    height: 58px !important;
  }
}
span.profile-picture-container {
  box-sizing: border-box;
  display: block;
  border-radius: 40px;
}

#patient-name-and-picture,
.treatment-picture {
  &.open-alert span.profile-picture-container {
    border: 4px solid #e9434a;
    padding: 3px;
  }
}
span.profile-picture-container {
  box-sizing: border-box;
  display: block;
  border-radius: 40px;
}
.treatment-picture.open-alert .profile-picture-container img {
  box-sizing: border-box;
}
.caseload-page .admin-page-header .button-new {
  width: 130px;
}
