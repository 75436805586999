@import '../variables.less';

header#patient-treatment-header {
  background: @backgroundColor;
  display: flex;
  justify-content: space-between;
  padding: 20px 48px 0px 30px;
  align-items: center;
  height: @t_r_header_height;

  .icons {
    display: flex;
  }
}
#patient-treatment-header svg {
  cursor: pointer;
}
.task-opened #patient-treatment-header .icons {
  margin-right: @task_bar_width;
}
#patient-name-and-picture {
  display: flex;
  align-items: center;
}
#patient-name-container {
  font-size: 26px;
  font-weight: 700;
  margin-left: 15px;

  .pronouns {
    color: rgba(84, 88, 93, 0.6);
    font-weight: 400;
  }

  .patient-name {
    display: flex;
    align-items: center;
  }
}

#patient-picture {
  width: 68px;
  height: 68px;
  border-radius: 40px;
}
.treatment-room-container {
  // display: flex; //be carefull, cause bug when open modal in channel
  height: @t_r_content;
  overflow: auto;
}
.task-opened .treatment-room-container {
  // TODO: remove when PROJECT_MOVE_CHANNEL_TO_SIDEBAR flag will be removed
  width: calc(100% - @task_bar_width);
}
.app-content.notification-pinned .treatment-room-container > #treatment-chat {
  width: calc(100% - @pinned-notification-width);
}

.app-content.notification-pinned
  .profile-open.treatment-room-container
  > div:first-child {
  max-width: calc(
    100% - @patient-profile-width - @pinned-notification-width
  ) !important;
}
.app-content.notification-pinned
  .profile-open.treatment-room-container
  > #treatment-chat {
  max-width: calc(
    100% - @patient-profile-width - @pinned-notification-width
  ) !important;
}
// .ReactModal__Body--open .treatment-room-container > div:not([id]) {
//   display: none;
//   visibility: hidden;
//   width: 0 !important;
// }
// .ReactModal__Body--open .treatment-room-container > div:nth-child(2) {
//   width: 100%;
// }
.alert-date {
  font-weight: bold;
  background-color: @backgroundColor;
  padding: 10px;
  margin-bottom: 8px;
  margin-top: 14px;
}
.event-row {
  display: flex;
  align-items: center;
  height: 60px;
}

.event-row .message-content {
  background-color: initial;
}
.sub-menu-nav .options-menu-container,
.sub-menu-nav .option-item {
  margin-right: 0;
  float: initial;
}
.sub-menu-nav .options-menu-container {
  display: inline-block !important;
  margin-top: 11px;
}
#patient-name-and-picture .discharge-reason {
  margin-left: 11px;
  margin-bottom: 5px;
}
div#start-video-call {
  height: 36px;
  padding-left: 5px;
  margin-right: 12px;

  path {
    stroke: white;
    fill: white;
  }
}
.open-profile-icon {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.disabled-chat {
  color: @redColor;
  border: 1px solid @redColor;
  padding: 3px 10px;
  border-radius: 15px;
  margin-left: 10px;
  font-size: initial;
  font-weight: initial;
  display: inline-block;
}
.sub-menu-nav .options-menu.left-direction {
  margin-right: 0px;
}
