@import "../variables.less";

.medication-popup {
    width: 430px;
}
.medication-info {
    display: grid;
    grid-template-columns: 35% 65%;
    padding: 15px 0;
}
.bottom-border {
    border-bottom: 1px solid @grayColor;
}
.content.patient-note {
    max-height: 150px;
    overflow: auto;
}