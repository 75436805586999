@import '../../variables.less';

.form-line-text svg {
  float: left;
}
.edit-patient-popup {
  background: white;
  min-width: 600px;
  max-width: calc(100vw - 300px);
}

.edit-patient-popup span.edit-patient-label {
  white-space: nowrap;
  display: block;
  width: 200px;
}
.edit-patient-popup span.edit-patient-label {
  margin-left: 30px;
}
.edit-patient-popup input,
.edit-patient-popup select {
  width: 100% !important;
}
// .show-less .form-comp {
//   grid-template-columns: auto auto;
//   grid-column-gap: 40px;
// }
.no-tags-container {
  text-align: center;
}
.no-tags {
  font-style: oblique;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}
.link-click b {
  color: inherit;
}
section.edit-patient-area {
  padding: 15px 10px;
  margin-top: 15px;
  border-top: 1px solid #ccc;
}
section.edit-patient-area .form-line {
  align-items: baseline;
}
.edit-patient-popup .popup-body {
  max-height: calc(100vh - 300px);
  overflow: auto;
}
.plans-options-container {
  display: grid;
  grid-template-columns: 10% 10% 80%;
  align-items: center;
}
.care-team-options-container {
  display: grid;
  grid-template-columns: 10% 20% 70%;
  align-items: center;
}

.care-team-options-container .menu-list-item-icon,
.plans-options-container .menu-list-item-icon {
  margin-right: 8px;
  display: grid;
}
.plan-options-title {
  // max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cm-option-picture img {
  height: 32px;
  width: 32px;
  border-radius: 30px;
}
.cm-options-container {
  display: flex;
  align-items: center;
}
.care-team-options-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.cm-option-picture {
  margin-right: 10px;
}
#patient-deviceid {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 15px;
  margin-top: 15px;
}
.edit-patient-popup .form-line {
  grid-template-columns: 200px auto;
}
.edit-patient-popup .popup-footer {
  position: relative;
}
.selected-plan-icon {
  width: 14px;
  height: 14px;
  margin-right: 7px;
  display: inline-block;
}
.edit-patient-popup .form-comp {
  padding: 0 !important;
}
.edit-patient-popup .dashboard-datepicker {
  width: 100%;
}
.add-supportive-contact-container {
  border-top: 1px solid #dadada;
  margin-top: 10px;
  padding-top: 10px;
}

.add-supportive-contact-area .form-comp {
  margin-top: 10px;
}
.mobile-app.form-line {
  grid-template-columns: 280px auto;

  .form-line-text.form-input-container {
    text-align: right;
    align-self: end;
  }
}
.remove-address-icon {
  margin-right: 6px;
  cursor: pointer;
}
