@import "../../../variables.less";

.treatment-select {
  width: 30px;
  text-align: center;
  /* pointer-events: none; */
}
.treatment-select input {
  visibility: hidden;
  pointer-events: all;
}
.treatment-select.visible input {
  visibility: visible;
}
.selected-row {
  background-color: #f8f9f9;
}

.caseload-row:hover .treatment-select input {
  visibility: visible;
}
.treatment-select {
  cursor: initial !important;
}
.caseload-row:hover .treatment-select input,
.caseload-row.selected-row .treatment-select input {
  visibility: visible !important;
  align-self: center;
  cursor: pointer;
}

.name-container {
  display: flex;
}

.name-and-diagnosis .name {
  font-weight: bold;
  letter-spacing: 1px;
}

.connect-last-days {
  margin-left: 5px;
}

span.next-appointment {
  margin-left: 5px;
}
.next-appointment-container,
.open-tasks-container,
.clinical-indicators-container {
  margin-bottom: 8px;
}
.clinical-indicators-container {
  display: flex;
}

.clinical-indicators-element {
  margin-left: 10px;
  display: flex;
}
.diagnose {
  margin-right: 8px;
  margin-top: 5px;
}
.clinical-indicators-element span {
  margin-left: 2px;
}
span.open-tasks {
  margin-left: 7px;
}
.care-plan {
  align-self: baseline;
  vertical-align: top !important;
}
.event-channel {
  width: 300px;
  vertical-align: baseline !important;
  margin-right: 20px;
  max-width: 580px !important;
}
.care-plan-element {
  padding: 0px 10px;
  border: 0.5px solid #dadcdf;
  border-radius: 20px;
  background-color: #ffffff;
  display: inline-block;
  margin-right: 10px;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.treatment-link {
  width: 20px;
}
.treatment-row-options {
  width: 40px;
}
.event-channel-messages {
  background: @grayColor;
  padding: 8px;
  border-radius: 0 9px 9px 9px;
  min-height: 50px;
  position: relative;
}
.no-chats {
  display: block;
}

.message-line {
  display: flex;
  position: relative;
  justify-content: space-between;
}
.no-chats,
.no-careplan {
  font-style: oblique;
}
.message-name {
  font-weight: bold;
  letter-spacing: 0.7px;
}

.message-time {
  font-size: 8px;
}
.event-channel-messages .icon-count {
  position: absolute;
  right: 5px;
  bottom: 8px;
}

.discharge-reason-container {
  display: table-cell;
}

.discharge-caseload-page .treatment-picture img {
  filter: grayscale(100%);
}
.pending-button {
  text-align: end;
}
td.pending-button .button-new {
  width: 140px;
  display: inline-block;
}
.treatment-row-options {
  text-align: right;
}
.delete-pending-confirm {
  display: flex;
}
.delete-pending-confirm .text {
  max-width: 340px;
  margin-left: 25px;
}
.treatment-app-score {
  vertical-align: top !important;
}
.treatment-mrn {
  margin: 8px 0;
}