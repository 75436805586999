@import "../variables.less";

@settings-header-height: 150px;

.user-profile-content {
  height: calc(@dashbord_content_height - @settings-header-height);
  overflow: auto;
}

.update-picture-line img {
  height: 92px;
  width: 92px;
  border-radius: 50px;
}

.update-picture-line {
  display: flex;
  align-items: center;
  height: @settings-header-height;
  padding-bottom: 18px;
  border-bottom: 1px solid @borderColor;
}
.settings-part {
  margin: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid @borderColor;
}

.settings-part.personnal-info {
  margin-top: 0;
  padding-top: 50px;
}
.settings-part .button-new.secondary-button {
  width: fit-content;
}

.user-profile-container header.update-picture-line {
  background: #f8f9f9;
  padding-left: 20px;
}
.user-profile-details {
  margin-top: 35px;
  margin-bottom: 35px;
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 20px;
}

.user-profile-details .title {
  font-weight: bold;
}

.user-profile-container .form-comp {
  width: 30%;
}

.user-profile-container {
  // height: @dashbord_content_height;
  // background-color: #f0f2f5;
  // display: flex;
  // justify-content: center;
}
.user-profile {
  width: 400px;
}

.user-profile .form-comp {
  margin-top: 20px;
}
.update-picture-line .button-new {
  margin-left: 55px;
}

.user-profile-container > .button-new {
  display: inline-block;
  margin-left: 150px;
  margin-top: 5px;
}

a.profile-account-container.activate-nav .test {
  border: 2px solid #ffffff;
  border-radius: 30px;
}
.notifications-description {
  max-width: 250px;
}
.notifications-preferences-table td:nth-child(2) {
  padding: 0 20px;
}
.notifications-preferences-table tr:nth-child(4) td {
  padding-top: 10px;
}
.notifications-preferences-table td svg {
  margin-right: 7px;
}
.edit-date-popup .form-comp {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  padding-top: 0;
}
.edit-date-popup {
  width: 470px;
}
.edit-date-popup .react-date-picker,
.edit-date-popup .timepicker {
  width: 150px;
}
span.date-time {
  text-align: right;
  margin-right: 20px;
}
.schedule-line {
  margin: 25px 0;
}

.schedule-line-title {
  font-weight: bold;
  width: 120px;
  height: 50px;
}
.edit-date-popup-line .form-line-text.text-field span {
  display: none;
}
.edit-date-popup .invalid-message {
  position: absolute;
}
#patient-name {
  font-size: 26px;
  font-weight: 700;
  margin-left: 15px;
}
.edit-date-popup-line {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.edit-date-popup-line form {
  width: 85%;
}

.edit-date-popup-line .link-click {
  margin-left: 30px;
}
.edit-date-popup-line .form-comp {
  padding: 0;
}

.covering-user {
  display: flex;
  align-items: center;
}

.covering-user .profile-picture-container img {
  width: 32px;
  height: 32px;
  border-radius: 30px;
}

span.covering-name {
  margin-left: 12px;
}
td.schedule-line-date {
  padding: 0 10px;
}
td.schedule-line-date svg {
  margin-right: 10px;
}
.edit-date-popup-title.title {
  margin-bottom: 8px;
}
.edit-date-popup-explanation {
  margin: 8px 0px 16px;
}