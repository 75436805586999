@import "../../variables.less";

.caseload-filters {
  border-right: 1px solid @borderColor;
  width: 300px;
  overflow: auto;
}
.caseload-filters .title {
  color: #54585d;
  font-size: 13px;
  font-weight: 300;
  padding-left: 15px;
}

.filter-part-header {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 12px;
}

.filter-part-title {
  font-size: 13px;
  font-weight: 900;
  letter-spacing: 0.7px;
}

.filter-part {
  margin-top: 20px;
}

.filter-part-body .link-click,
.toggle-advancer-filter {
  padding: 15px;
}
.filter-part-body .check-list-element {
  padding-left: 15px;
  padding-right: 12px;
}
.link-click [class^="arrow-"] {
  border-color: #4285f4;
}
.filter-part-body .check-list-element:hover,
.filter-part-body .path-filter-container:hover {
  background-color: @backgroundColor;
}

.filter-part-body .path-filter-container {
  padding: 0 15px;
}
.filter-part-body .form-line-text select,
.filter-part-body .form-line-text input {
  width: -webkit-fill-available;
}
.filter-part-body .form-range {
  display: flex;
}
.filter-part-body .form-range-link {
  margin: 10px;
}
.form-range-part input {
  width: 60px !important;
}
.caseload-filters .form-comp {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.border-separate-filters {
  border: 1px solid @borderColor;
  margin: 15px;
}
.server-filter-note {
  color: #54585d;
  font-style: oblique;
  padding: 0 15px;
  width: 230px;
}
.clinical-indicator-header {
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.clinical-indicator-header.opened {
  margin-bottom: 15px;
}
.clinical-indicator-header [class^="arrow-"] {
  width: 6px;
  height: 6px;
}

.clinical-indicator-header span {
  margin-right: 5px;
}

.clinical-indicator-filter:not(:last-child) {
  border-bottom: 1px solid @borderColor;
  padding-bottom: 10px;
}
.server-filter-footer {
  display: flex;
  align-items: center;
}
.server-filter-footer {
  display: flex;
  align-items: center;
}

.clinical-indicator-filter {
  margin: 15px;
}

.clinical-indicator-filter .form-comp {
  padding: 0 !important;
}
.care-team-filter {
  padding: 15px;
}
.care-team-filter .path-filter-container {
  margin-top: 15px;
}
.care-team-filter .check-list-container {
  width: 200px;
}
.care-team-filter .path-filter {
  padding: 0;
  height: auto;
}
