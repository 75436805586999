@import "../variables.less";

.caseload-table .caseload-row {
  position: relative;
}
a.caseload-row-link {
  text-decoration: none;
  display: contents;
}
.caseload-row td {
  vertical-align: middle;
  padding: 12px;
  border-bottom: 1px solid @borderColor;
}
.caseload-row.header-row {
  cursor: initial;
}
.caseload-row.header-row th {
  border-top: 1px solid @borderColor;
  border-bottom: 1px solid @borderColor;
  padding-top: 20px;
  padding-bottom: 20px;
}
