@import "../../variables.less";

.selected-element {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    border: 1px solid @borderColor;
    border-radius: 4px;
    background-color: #f8f8f9;
    float: left;
    margin-right: 10px;
    margin-top: 10px;
    height: 36px;
  }
  span.selected-element-title {
    white-space: nowrap;
    margin-right: 5px;
    max-width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
