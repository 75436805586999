@import "../../variables.less";

.system-message-element.message-content {
  display: flex;
  align-items: center;
  border-color: @redColor;
  margin-bottom: 8px;
}

.resolved-date {
  color: #abadaf;
  font-weight: 300;
}

.resolved-reason {
  margin-top: 16px;
}

.resolve-list {
  margin-top: 20px;
  max-height: 320px;
  overflow: auto;

  .alert-description {
    font-weight: bold;
  }
  .system-message-element.message-content:not(:last-child) {
    margin-bottom: 8px;
  }
}
.resolve-alerts-popup {
  background-color: #ffffff;
  .popup-body {
    padding-top: 0;
  }
}
