@import "../../variables.less";

.list-footer {
  background-color: #ffffff;
  box-shadow: 0 -6px 10px 0 rgba(213, 213, 213, 0.5);
  height: @caseloadFooterHeight;
  min-height: @caseloadFooterHeight;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.footer-buttons {
  display: flex;
  justify-content: space-between;
  width: 270px;
  margin: auto;
}
.list-footer-num label {
  margin-left: 10px;
  vertical-align: text-bottom;
}
.list-footer .remove-icon {
  position: absolute;
  right: 100px;
  cursor: pointer;
}
.caseload-page {
  .list-footer {
    justify-content: space-between;
  }
  .list-footer {
    padding: 0 28px;
  }
  .footer-buttons {
    margin: initial;
  }
  .list-footer .remove-icon {
    position: initial;
    right: initial;
  }
}
