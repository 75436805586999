@import "../../../variables.less";

.timeline-item-details-container .icon-and-title .icon svg {
  width: 15px;
  height: 15px;
}
.timeline-item-details-container .icon-and-title .icon {
  margin-right: 10px;
  width: 15px;
  height: 15px;
}
.timeline-item-details-container .category {
  color: #abadaf;
  font-weight: 300;
  margin-bottom: 2px;
}
.timeline-item-details-container .description {
  margin-top: 5px;
}
.timeline-item-details-container {
  border-top: 0.5px solid #dadcdf;
  padding-top: 10px;
}

.timeline-item-title-container {
  padding-bottom: 10px;
}
.context-icon svg {
  height: 14px;
  width: 14px;
}
.context-container {
  display: inline-flex;
  margin-top: 10px;
  padding: 4px 8px;
  border-radius: 9px;
  background-color: @grayColor;
  color: #54585d;
  font-weight: 500;
}
.context-container .context-icon {
  margin-right: 10px;
}
.context-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}
.timeline-item-title-container .timeline-item-title {
  font-weight: bold;
}
.timeline-item-title-container > svg {
  margin-left: auto;
  cursor: pointer;
}

.icon-and-title{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3; 
  -webkit-box-orient: vertical;
}