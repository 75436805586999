@import "../../variables.less";

.caseload-header {
  display: flex;
  padding-left: 15px;
  // height: @assignmentHeader;
  // align-items: center;
}

.caseload-header-left-side {
  display: flex;
  width: 260px;
}

.caseload-header-left-side .input-search {
  margin-left: 16px;
}
.caseload-header-left-side .input-search input {
  width: 186px;
}
.caseload-discharge-header {
  height: 60px;
  background-color: @backgroundColor;
  font-weight: 900;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: space-between;
}
.discharge-header {
  font-size: 22px;
}
