@import '../variables.less';

@chatFooterHeight: 66px;

.treatment-room-container > div:empty {
  display: none;
}
.own-message {
  text-align: right;
}

.own-message .message {
  display: block;
  direction: rtl;
}
.own-message .message-content {
  margin-left: 40px;
}
.treatment-chat-container {
  height: @t_r_content;
}
#treatment-chat {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;

  #chat-body {
    flex: 1;
    overflow-y: auto;
  }

  &.old-chat {
    width: 100vw;

    #chat-body {
      height: calc(@t_r_content - @chatFooterHeight);
    }
  }

  #chat-footer {
    padding: 15px;
    justify-self: center;
    align-self: end;
    width: 100%;
    height: @chatFooterHeight;
  }

  #chat-header {
    text-align: center;
    padding: 15px;
  }

  #chat-header .icon-button-component {
    justify-self: start;
  }
  .chat-title {
    display: inline-block;
    font-size: 30px;
  }
  #chat-header .icon-button-component {
    position: absolute;
  }

  .text-between-lines {
    text-align: center;
    overflow: hidden;
    margin: 16px 0;
    height: 20px;
  }
  .text-between-lines span {
    display: inline-block;
    background: #fff;
    position: relative;
  }
  .text-between-lines span:after,
  .text-between-lines span:before {
    content: '';
    position: absolute;
    height: 5px;
    border-bottom: 0.5px solid @borderColor;
    top: 5px;
    width: 100vw;
  }
  .unread-message.text-between-lines span:after,
  .unread-message.text-between-lines span:before {
    border-color: #e74243;
  }
  .text-between-lines span::before {
    right: 100%;
    margin-right: 15px;
  }

  .text-between-lines span::after {
    left: 100%;
    margin-left: 15px;
  }
  #message-dest select {
    border: none;
    font-weight: bold;
  }

  #message-dest {
    font-size: 14px;
  }

  #send-message textarea {
    width: 100%;
    border: none;
    border-radius: 10px;
    border: 1px solid rgba(100, 121, 143, 0.1);
    background-color: rgba(242, 245, 245, 0.8);
    height: 36px;
    padding-left: 45px;
    padding-right: 30px;
  }

  #send-message .send-button svg {
    position: absolute;
    right: 10px;
    top: 4px;
  }

  #send-message {
    position: relative;
  }
  .message-content-container {
    display: flex;
    justify-content: space-between;

    .options-menu-container {
      display: none;
    }
    &:hover .options-menu-container {
      display: block;
    }
  }

  .send-button {
    cursor: pointer;
  }
  .message-icon img {
    width: 32px;
    height: 32px;
  }
  .system-message {
    width: 300px;
    padding: 14px;
    border: 0.5px solid @borderColor;
    border-radius: 10px;
  }

  .system-message .message-content:not(:last-child) {
    margin-bottom: 8px;
  }
}

.message {
  display: grid;
}
.message-content {
  text-align: left;
  direction: ltr;
  border-radius: 10px;
  color: #54585d;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
  background-color: #f7f7f7;
  justify-self: start;
  padding: 10px;
  word-break: break-word;
  min-height: 36px;
}

.message-container {
  padding: 0 30px;
  margin-top: 30px;
}
.message-container:not(:first-child) {
  margin-top: 20px;
}
.no-date-message {
  margin-top: 5px !important;
}
.message-container {
  padding: 0 15px;
}
.message-container .message-icon {
  align-self: start;
}
.message-icon img {
  border-radius: 30px;
}
.message-date-and-content {
  display: grid;
  position: relative;
}
.message-date {
  color: #abadaf;
}

.unread-message,
.unread-message span {
  color: #e74243;
  border-color: #e74243;
}

.chat-date,
.chat-date span {
  color: #abadaf;
  border-color: #abadaf;
}

span.item-message-title-icon {
  margin-right: 12px;
  width: 17px;
}

.item-message-title {
  font-weight: bold;
  display: flex;
}
.media-message {
  width: 290px;
  border: 1px solid @borderColor;
  border-radius: 10px;
  cursor: pointer;
}
.media-message-footer {
  padding: 14px;
  text-align: left;
}
.media-message img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}
.media-title {
  font-weight: bold;
}
.system-message .media-message {
  width: auto;
}
.media-url {
  color: #abadaf;
  margin-top: 10px;
  word-break: break-word;
}
#send-message .options-menu-container {
  position: absolute !important;
  left: 50px;
  left: 10px;
  top: 4px;
}
.item-message-status {
  margin-left: 25px;
  margin-top: 5px;
}
.loading-more-container {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: center;
  position: absolute;
}

.loading-more-container .loader {
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(114, 114, 114, 0.5);
  border-radius: 40px;
  padding: 10px;
  margin-top: 22px;
  display: block;
  height: auto;
  width: auto;
  position: absolute;
}
span.message-type-icon {
  width: 21px;
  height: 21px;
  display: inline-block;
  margin-right: 10px;
}
.message-type-icon svg {
  width: 24px;
  height: 24px;
}
.system-message-details {
  margin-left: 4px;
}
.item-message-eye-icon {
  margin-left: auto;
  cursor: pointer;
}
#send-message span.option-item-icon {
  width: 13px;
  height: 20px;
  margin-right: 8px;
}
#send-message .option-item {
  display: flex;
  align-items: center;
}
.alert-message {
  margin: auto;
}
.alert-message .message-content {
  display: flex;
  align-items: center;
  border-color: @redColor;
  font-weight: bold;
}
.ctm-alert.message-content {
  font-weight: initial;
}
.unread-message {
  margin: 16px 15px;
}
.alert-message .message-type-icon {
  margin-right: 15px;
}
.highlight-word {
  font-weight: bold;
  color: #e9434a;
  padding: 0;
  background-color: initial;
}
.item-message-event-details,
.message-note,
.item-message-description {
  margin-left: 30px;
}
.item-message-event-details .content {
  margin-left: 10px;
}
.item-message-event-details,
.message-note {
  margin-top: 4px;
}
.discharge-message {
  font-size: 14px;
  font-weight: 300;
  opacity: 0.5;
}

.discharged-footer {
  background-color: #f7f7f7;
  text-align: center;
}
.channel-empty-state {
  margin-top: 30px;
  font-weight: 500;
}

.old-channel-ui {
  .message .options-menu-container {
    position: absolute !important;
    right: 0;
    top: 0;
    display: none;
  }
}
.new-channel-ui .message {
  .options-menu-container {
    position: absolute !important;
    right: 0;
    top: 0;
    display: none;
  }

  .patient-message-content .options-menu-container {
    left: 0;
    right: initial;
  }
}

.message:hover .options-menu-container {
  display: block;
}
.temp-message .message-content {
  color: rgba(84, 88, 93, 0.6);
}
.sending-message {
  direction: ltr;
  font-size: 12px;
  color: rgba(84, 88, 93, 0.6);
}
