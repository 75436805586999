html,
body {
  font-size: 16px;
}
body {
  margin: 0;
  line-height: 1.5;
  text-align: left;
}
*,
:after,
:before {
  box-sizing: border-box;
}
svg {
  overflow: hidden;
}
img,
svg {
  vertical-align: middle;
}
a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}
#app {
  margin: 0 auto;
}
:root {
  --main-font-color: #317b8c;
}
table {
  border-collapse: collapse;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
