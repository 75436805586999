.user-message-pipe {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 10px;
  bottom: 0px;
  z-index: 100;
  font-size: 13px;
}
.user-message-item {
  display: flex;
  flex-direction: row;
  min-height: 84px;
  width: 411px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 25px;
  align-items: center;
  &.warning-message {
    border-left: 5px solid #fbd28d;
  }
  &.success-message {
    border-left: 5px solid #3ac696;
  }
  &.error-message {
    border-left: 5px solid #e04147;
  }
  .message-icon {
    padding: 20px;
  }
  .message-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    .message-title {
      font-weight: 900;
      margin-bottom: 5px;
    }
  }
  .close-icon {
    padding: 20px;
  }
}
