@import "../../variables.less";

@tableRightMargin: 30px;

.treatment-line {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 16px;
}

.caseload-table {
  display: table;
  margin: 0 @tableRightMargin;
  width: calc(100% - 2 * @tableRightMargin);
  border-collapse: separate;
  border-spacing: 0;
}
.caseload-table-container {
  width: 100%;
  overflow-y: auto;
  position: relative;
}
thead.caseload-row.header-row th {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 1;
  padding-left: 12px;
}
.pending-page .caseload-table-container,
.discharge-caseload-page .caseload-table-container {
  height: calc(@treatmentListContentHeight + @caseloadPaddingTop + 25px);
}
.with-footer .caseload-table-container {
  // height: calc(@treatmentListContentHeight - @caseloadFooterHeight);
}
.row {
  grid-area: row;
  border-bottom: 1px solid black;
}

.name-and-diagnosis {
  vertical-align: top !important;
  max-width: 300px;
}
td.event-channel {
  width: 400px;
}
th.pending-date {
  // display: flex;
  // align-items: center;

  .arrow-top {
    margin-top: 4px;
  }
}
th.treatment-details {
  width: 270px;
}